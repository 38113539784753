import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import styles from "./sav.module.scss"
import Seo from "../components/seo"

const Sav = () => {
  return (
    <Layout>
      <Seo
        title="service après-vente de Wistibike"
        description="trouvez ici tous les moyens de communication pour joindre le service après-vente de Wistibike"
      />
      <main>
        <section className={styles.topSection}>
          <h1>Service Après-vente</h1>
          <p>
            Nous vous accompagnons après l’achat d’un produit sur notre site :
            entretien, retours, pièces détachées.
          </p>
        </section>
        <section className={styles.bottomSection}>
          <section className={styles.bottomContainer}>
            <article className={styles.items}>
              <div className={styles.itemContainer}>
                <div className={styles.cross}></div>
                <p>Appelez notre service Après-vente :</p>
              </div>
              <div className={styles.contact}>
                <a href="tel:0612589577">06 12 58 95 77</a>
              </div>
            </article>
            <article className={styles.items}>
              <div className={styles.itemContainer}>
                <div className={styles.cross}></div>
                <p>Contactez-nous à cette adresse e-mail :</p>
              </div>
              <div className={styles.contact}>
                <a href="mailto:contact@wistibike.com">contact@wistibike.com</a>
              </div>
            </article>
            <article className={styles.items}>
              <div className={styles.itemContainer}>
                <div className={styles.cross}></div>
                <p>Contactez-nous directement via notre application :</p>
              </div>
              <div className={styles.contact}>
                <Link to="/contactez-nous">Contactez-nous</Link>
              </div>
            </article>
          </section>
        </section>
      </main>
    </Layout>
  )
}

export default Sav
